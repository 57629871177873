<template>
  <div class="xiaoheHouseKeeper">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <v-button text="新增" @click="toAdd"></v-button>
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input label="姓名/手机号" v-model="searchParam.keyword" />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          @click="toEdit(scope.row)"
        />

        <v-button text="删除" type="text" @click="toCancel(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getButlerListUrl,
  deleteButlerUrl,
  activityAudit,
  donateAudit,
} from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import {} from "./map.js";

export default {
  name: "applyActivity",
  data() {
    return {
      searchParam: {
        communityId: null,
        keyword: "",
      },
      communitList: [],
      tableUrl: getButlerListUrl,
      headers: [
        {
          prop: "communityName",
          label: "所属地区",
        },
        {
          prop: "blockName",
          label: "楼栋",
        },
        {
          prop: "name",
          label: "管家姓名",
        },

        {
          prop: "mobile",
          label: "管家手机号",
        },
        {
          prop: "evaluateScore",
          label: "管家评分",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    toAdd(data) {
      this.$router.push({
        name: "xiaoheHouseKeeperForm",
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "xiaoheHouseKeeperForm",
        query: { id: data.id },
      });
    },
    toCancel(data) {
      this.$confirm("你确定要删除吗", "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`${deleteButlerUrl}`, null, {
              params: {
                ids: data.id,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("取消成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },
  },
};
</script>
<style lang="less" scoped>
.xiaoheHouseKeeper {
  box-sizing: border-box;
  height: 100%;
}
</style>
