//管家列表
const getButlerListUrl = `/gateway/hc-neighbour/butler/getButlerList`;
//新增管家
const addButlerUrl = `/gateway/hc-neighbour/butler/saveButlerInfo`;
//管家详情
const butlerDetailUrl = `/gateway/hc-neighbour/butler/getButlerInfoById`;
//删除管家
const deleteButlerUrl = `/gateway/hc-neighbour/butler/deleteButler`;
//检验手机号
const butlerInfoEdit = `/gateway/hc-neighbour/butler/butlerInfoEdit`;
//获取建筑列表
// const getBuildListURL = `/gateway/hc-space/space/building-list`;
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;
export {
  getButlerListUrl,
  addButlerUrl,
  butlerDetailUrl,
  deleteButlerUrl,
  getBuildListURL,
  butlerInfoEdit
};
