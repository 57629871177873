import { mapHelper } from "@/utils/common.js";
const userType = [
  {
    value: "4",
    checked: false,
    label: "居民",
  },
  {
    value: "5",
    checked: false,
    label: "企业/商户",
  },
  // {
  //   value: "21",
  //   checked: false,
  //   label: "运营人员",
  // },
];

const { map: userTypeMap, setOps: setUserTypeOps } = mapHelper.setMap(userType);
export { userTypeMap, setUserTypeOps };
